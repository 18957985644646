import Masonry from 'masonry-layout';

// Extracted CSS selectors as constants
/**
 * A constant variable representing the selector of the image grid element (masonry style).
 *
 * @constant {string}
 * @default '.c-image-grid--masonry'
 * @description This selector is used to target the image grid element that has the masonry layout style.
 */
const IMAGE_GRID_SELECTOR = '.c-image-grid--masonry';
/**
 * CSS selector for selecting an individual item within an image grid.
 *
 * @constant {string}
 * @default '.c-image-grid__item'
 */
const IMAGE_GRID_ITEM_SELECTOR = '.c-image-grid__item';

/**
 * Applies masonry layout to the image grid.
 *
 * @function applyMasonryLayoutToImageGrid
 * @returns {void}
 */

const applyMasonryLayoutToImageGrid = () => {
    const grid = document.querySelector(IMAGE_GRID_SELECTOR);

    // Check if the grid exists on the page
    if (grid) {
        new Masonry(grid, {
            itemSelector: IMAGE_GRID_ITEM_SELECTOR,
            columnWidth: 80,
            gutter: 16,
        });
    }
}

window.addEventListener('DOMContentLoaded', applyMasonryLayoutToImageGrid);